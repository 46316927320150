import service from "../../api";
import stateData from "./state";

export default {
  // 获取登录信息
  getLoginData ({ rootState, commit }) {
    let { uid = "" } = rootState.userReport;
    return service.getMapData({ uid, pageNum: 1, pageSize: 10 }).then(res => {
      let { list = [] } = res;
      commit("setLoginList", list);
    });
  },
  // 获取地图列表
  getList ({ rootState, commit, dispatch }, params) {
    let { uid = "" } = rootState.userReport;
    return service.getMapData(Object.assign({ uid }, params)).then(res => {
      let { list = [], page = {} } = res;
      commit("setMapList", list);
      commit("setMapPage", page);
      dispatch("drawMap", list[0] || {});
    });
  },
  // 获取地图列表
  getMapList ({ rootState, commit, dispatch }, params) {
    let { uid = "" } = rootState.userReport;
    return service.getMapData(Object.assign({ uid }, params)).then(res => {
      let { list = [], page = {} } = res;
      commit("setMapList", list);
      commit("setMapPage", page);
    });
  },
  // 实名认证信息
  getVerify ({ rootState, commit,dispatch }, hasLoading = true) {
    // 初始化verify state
    commit("setVerify", stateData);
    if (hasLoading) commit("userReport/setLoading", true, { root: true });
    let { uid = "" } = rootState.userReport;
    return service.getVerify({ uid }).then(res => {
      commit("setVerify", res);
      commit("setMobiles", res.otherUser);
      dispatch("drawMap", res.location || {});

      // commit("userReport/setShowView", true, { root: true });
      if (hasLoading) commit("userReport/setLoading", false, { root: true });
    });
  },
  // 绘画地图
  drawMap (_, row) {
    if (Object.keys(row).length) {
      let { lat = "", lon = "" } = row;
      let map = new AMap.Map("login-map", {
        zoom: 16,
        center: [lon, lat]
      });
      let marker = new AMap.Marker({
        position: new AMap.LngLat(lon, lat)
      });
      map.add(marker);
    } else {
      let map = new AMap.Map("login-map", { zoom: 16 });
    }
  },
  //获取范围人数
  getPerson ({ rootState, commit, dispatch }, radius) {
    let { uid = "" } = rootState.userReport;
    return service.getRangePerson({
      radius,
      uid
    }).then(res => {

      if (res.current) {
        let obj = {};
        obj.count = res.count;
        obj.lon = res.current.lon;
        obj.lat = res.current.lat;
        commit("setRangeData", obj);
      }
    })
  }
};
