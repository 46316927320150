<template>
  <div class="verify" id="verify-tab">
    <document-generator/>
    <view-block :title="'基础认证信息'">
      <div class="verify-live">
        <div class="ft-sz-13">
<!--          <span>对比相似度：</span>-->
<!--          <span class="c-blue similar">-->
<!--            <span class="ft-sz-20 ft-wt-900">{{ userInfo.similarity || ''}}分</span>-->
<!--            <span class="ft-bold">(分值越高越安全)</span>-->
<!--          </span>-->
        </div>
        <div class="verify-live-img fx-m-between">
          <div class="card" v-for="(item, index) in idCardList" :key="index">
            <el-image class="card-img" :src="item.img" :preview-src-list="cardImgList">
              <div
                slot="error"
                class="image-slot"
                :style="{'backgroundImage': 'url(' + item.defaultImg + ')'}"
              ></div>
            </el-image>
            <div class="c-dkGrey ft-sz-13" style="margin-bottom: 20px;">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </view-block>


    <view-block :title="'进件位置'" >

<!--      <el-table-->
<!--        border-->
<!--        :data="[location]"-->
<!--        style="width: 100%"-->
<!--        header-row-class-name="light-head"-->
<!--        row-class-name="ft-sz-13 ft-bold"-->
<!--        max-height="500"-->
<!--        size="mini"-->
<!--      >-->
<!--        <el-table-column prop="createdAt" label="认证时间" align="center"></el-table-column>-->
<!--        <el-table-column prop="lon" label="经度" align="center"></el-table-column>-->
<!--        <el-table-column prop="lat" label="纬度" align="center"></el-table-column>-->
<!--        <el-table-column prop="addressComponent" label="城市" align="center"></el-table-column>-->

<!--      </el-table>-->

<!--      <el-pagination-->
<!--        background-->
<!--        :page-sizes="pageSizes"-->
<!--        :page-size="10"-->
<!--        layout="total, sizes, prev, pager, next"-->
<!--        :total="page.allNum"-->
<!--        @size-change="changeSize"-->
<!--        @current-change="changePage"-->
<!--      ></el-pagination>-->
      <div class="verify-map" id="login-map"></div>
    </view-block>

    <view-block :title="'基础信息'" v-if="baseInfo.length">
      <div class="verify-info">
        <ul class="fx-wrap c-dkGrey ft-sz-13 ft-bold">
          <li v-for="(item, index) in baseInfo" :key="index">
            <span>{{ item.label }}</span>
            <span class="c-dkGrey">{{ item.desc }}</span>
          </li>
        </ul>
      </div>
    </view-block>
  </div>
</template>

<script>
import viewBlock from './view-block';

import {mapGetters, mapState, mapActions, mapMutations} from 'vuex';
import pagination from '@/mixins/pagination';
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
export default {
  mixins: [pagination],
  props: {
    similar: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      showImage: false,
    };
  },
  components: {
    DocumentGenerator,
    viewBlock
  },
  computed: {
    ...mapState('userReport/verify', ['userInfo', 'appInfo', 'mapList', 'page', 'loginList', 'rangeData','location']),
    ...mapState('userReport', ['range', 'debtStatus']),
    ...mapGetters('userReport/verify', ['idCardList', 'cardImgList', 'baseInfo','debtList1', 'debtList2', 'debtList3'])
  },
  methods: {
    ...mapActions('userReport/verify', ['drawMap', 'getPerson']),
    ...mapMutations("userReport", ["setRange"]),
    rangeClick(command){
      this.range = command;
      this.setRange(command);
      this.getPerson(command)
    }
  }
};
</script>

<style lang="scss" scoped>
.verify {
  &-live {
    .similar {
      margin-right: 20px;
    }
    &-img {
      padding: 24px 8% 24px 18px;
      .card {
        width: 300px;
        height: 340px;
        text-align: center;
        border: 3px solid rgba(247, 248, 252, 1);
        &-img {
          width: 100%;
          height: 190px;
          margin-top: 50px;
          margin-bottom: 20px;
        }
      }
    }
  }
  &-info {
    li {
      min-width: 33%;
      margin-bottom: 20px;
    }
  }
  &-app {
    padding: 1px 5px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid rgba(160, 160, 160, 1);
    margin-right: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    font-weight: normal;
    //&.isHit {
    //  border: 1px solid red;
    //  color: red;
    //}
  }
  .tip {
    background-color: rgba(217, 243, 214, 1);
    padding: 10px 22px;
    margin-bottom: 20px;
  }
  .verify-map {
    width: 100%;
    height: 350px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 20px;
    border: 5px solid rgba(236, 242, 249, 1);
  }
  img.el-image-viewer__img {
    transform: scale(1) rotate(0) !important;
  }
  .peoples{
    background-color: rgb(217,243,214);
    padding:10px;
    margin-bottom:10px;
    .el-dropdown-link {
      cursor: pointer;
      color: #409EFF;
    }
    .person{
      padding:0 5px;
    }
  }
}
@media screen and (max-width: 480px) {
  .verify-live-img{
    display: block;
  }
  .card{
    width: 100%!important;
    height: auto!important;
    margin-bottom: 1rem;
    &-img {
      margin-top: 1.5rem!important;;
      margin-bottom: 1rem!important;;
    }
  }
  .verify-info li{
    display: block;
    width: 100%;
  }
}
</style>
