<template>
  <div class="tc document-view">
    <document-generator/>
    <div class="jilu">
      <p class="title ft-bold">法院综合</p>
      <p>
        <span>上次生成时间：{{ date }}</span
        ><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <div style="text-align: center;padding-top:10px;background: #fff">

      <radar-block title="法院信息">
        <div class="radar-behavior">
          <el-table border :data="courtDetailPro.entryList" style="width: 100%" header-row-class-name="light-head"
                    :row-class-name="tableRowClassName">
            <el-table-column type="expand" >
              <template slot-scope="props" >
                <div class="memo">
                <el-form label-position="left" label-width="100px" style="margin-left: 50px" >
                  <el-form-item label="标题">
                    <span>{{ props.row.title }}</span>
                  </el-form-item>
                  <el-form-item label="立案时间" v-if="props.row.sortTimeString">
                    <span>{{ props.row.sortTimeString }}</span>
                  </el-form-item>
                  <el-form-item label="文书类型" v-if="props.row.caseType">
                    <span>{{ props.row.caseType }}</span>
                  </el-form-item>
                  <el-form-item label="案由" v-if="props.row.caseCause">
                    <span>{{ props.row.caseCause }}</span>
                  </el-form-item>
                  <el-form-item label="被执行人" v-if="props.row.pname">
                    <span>{{ props.row.pname }}</span>
                  </el-form-item>
                  <el-form-item label="身份证号" v-if="props.row.idcardNo">
                    <span>{{ props.row.idcardNo }}</span>
                  </el-form-item>
                  <el-form-item label="判决结果" v-if="props.row.judgeResult">
                    <span>{{ props.row.judgeResult }}</span>
                  </el-form-item>
                  <el-form-item label="案件状态" v-if="props.row.caseState">
                    <span>{{ props.row.caseState }}</span>
                  </el-form-item>
                  <el-form-item label="执行标的" v-if="props.row.execMoney">
                    <span>{{ props.row.execMoney }}</span>
                  </el-form-item>
                  <el-form-item label="内容摘要">
                    <span>{{ props.row.body }}</span>
                  </el-form-item>
                </el-form>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="dataType" label="查询项目" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.dataType == 'cpws'">裁判文书</div>
                <div v-if="scope.row.dataType == 'fygg'">法院公告</div>
                <div v-if="scope.row.dataType == 'bgt'">曝光台</div>
                <div v-if="scope.row.dataType == 'ktgg'">开庭公告</div>
                <div v-if="scope.row.dataType == 'ajlc'">案件流程</div>
                <div v-if="scope.row.dataType == 'wdhmd'">网贷黑名单</div>
                <div v-if="scope.row.dataType == 'zxgg'">执行公告</div>
                <div v-if="scope.row.dataType == 'shixin'">失信公告</div>
              </template>
            </el-table-column>
            <el-table-column prop="court" label="法院名称" align="center"></el-table-column>
            <el-table-column prop="caseNo" label="案号" align="center"></el-table-column>
            <el-table-column prop="matchRatio" label="匹配度" align="center"></el-table-column>


          </el-table>
          <div class="tip">
            • 匹配度取值为0-1，值越大，表示匹配程度越高。
          </div>
        </div>
      </radar-block>

      <radar-block title="法院个人执行">
        <div class="sect">命中失信被执行人</div>
        <div class="radar-behavior">
          <el-table default-expand-all border :data="getXianGao1" style="width: 100%" header-row-class-name="light-head"
                    :row-class-name="tableRowClassName">
            <el-table-column type="expand" >
              <template slot-scope="props" >
                <div class="memo">
                信号描述：{{props.row.signalDesc}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="名称" align="center">
            </el-table-column>
            <el-table-column prop="casenum" label="案号" align="center"></el-table-column>
            <el-table-column prop="court" label="法院名称" align="center"></el-table-column>
            <el-table-column prop="time" label="立案时间" align="center"></el-table-column>
            <el-table-column prop="money" label="执行金额" align="center"></el-table-column>
            <el-table-column prop="statute" label="案件状态" align="center"></el-table-column>
            <el-table-column prop="exp_bad1_sign" label="下架标识" align="center">
<!--              0为已下架,1/空/其他均为未下架-->
              <template slot-scope="scope">
                <div v-if="scope.row.exp_bad1_sign==='0'">
                  已下架
                </div>
                <div v-else>
                  未下架
                </div>
              </template>

            </el-table-column>
            <el-table-column prop="signalRating" label="信号等级" align="center">
            </el-table-column>


          </el-table>
        </div>
        <div class="tip">• 信号等级：取值1-7；1为风险最高，7为风险最低。</div>
        <div class="sect">命中法院被执行人</div>
        <div class="radar-behavior">
          <el-table default-expand-all border :data="getShiXin1" style="width: 100%" header-row-class-name="light-head"
                    :row-class-name="tableRowClassName">
            <el-table-column type="expand" >
              <template slot-scope="props" >
                <div class="memo">
                信号描述：{{props.row.signalDesc}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="名称" align="center">
            </el-table-column>
            <el-table-column prop="casenum" label="案号" align="center"></el-table-column>
            <el-table-column prop="court" label="法院名称" align="center"></el-table-column>
            <el-table-column prop="time" label="立案时间" align="center"></el-table-column>
            <el-table-column prop="money" label="执行金额" align="center"></el-table-column>
            <el-table-column prop="statute" label="案件状态" align="center"></el-table-column>
            <el-table-column prop="exp_bad1_sign" label="下架标识" align="center">
              <!--              0为已下架,1/空/其他均为未下架-->
              <template slot-scope="scope">
                <div v-if="scope.row.exp_bad1_sign==='0'">
                  已下架
                </div>
                <div v-else>
                  未下架
                </div>
              </template>

            </el-table-column>
            <el-table-column prop="signalRating" label="信号等级" align="center">
            </el-table-column>


          </el-table>
        </div>
        <div class="tip">• 信号等级：取值1-7；1为风险最高，7为风险最低。</div>
      </radar-block>


      <radar-block title="法院限高执行">
        <div class="sect">命中失信被执行人</div>
        <div class="radar-behavior">
          <el-table default-expand-all border :data="shixinList" style="width: 100%" header-row-class-name="light-head"
                    :row-class-name="tableRowClassName">
            <el-table-column type="expand" >
              <template slot-scope="props" v-if="props.row" >
                <div class="memo">
                <el-descriptions title=""  style="margin: 20px;background-color: aliceblue" :labelStyle="{'background-color': 'aliceblue'}" :contentStyle="{'background-color': 'aliceblue'}">
                  <el-descriptions-item label="标识自然人或企业">{{props.row.partytypename}}</el-descriptions-item>
                  <el-descriptions-item label="企业法人姓名">{{props.row.buesinessentity}}</el-descriptions-item>
                  <el-descriptions-item label="执行依据文号">{{props.row.gistcid}}</el-descriptions-item>
                  <el-descriptions-item label="发布时间">{{props.row.publishdate}}</el-descriptions-item>
                  <el-descriptions-item label="地域名称">{{props.row.areaname}}</el-descriptions-item>
                  <el-descriptions-item label="信号描述">{{props.row.signalDesc}}</el-descriptions-item>
                </el-descriptions>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="iname" label="名称" align="center">
            </el-table-column>
            <el-table-column prop="casecode" label="案号" align="center"></el-table-column>
            <el-table-column prop="sexname" label="性别" align="center"></el-table-column>
            <el-table-column prop="age" label="年龄" align="center"></el-table-column>
            <el-table-column prop="courtname" label="执行法院" align="center"></el-table-column>
            <el-table-column prop="publishdate" label="立案时间" align="center"></el-table-column>
            <el-table-column prop="exp_bad1_sign" label="下架标识" align="center">
              <!--              0为已下架,1/空/其他均为未下架-->
              <template slot-scope="scope">
                <div v-if="scope.row.exp_bad1_sign==='0'">
                  已下架
                </div>
                <div v-else>
                  未下架
                </div>
              </template>

            </el-table-column>
            <el-table-column prop="signalRating" label="信号等级" align="center">
            </el-table-column>


          </el-table>
        </div>

        <div class="sect">命中限高被执行人</div>
      <div class="radar-behavior">
        <el-table default-expand-all border :data="xiangaoList" style="width: 100%" header-row-class-name="light-head"
                  :row-class-name="tableRowClassName">
          <el-table-column type="expand" class-name="memo">
            <template slot-scope="props" v-if="props.row" style="margin: 20px">
              <div class="memo">
                信号描述：{{props.row.signalDesc}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="iname" label="名称" align="center">
          </el-table-column>
          <el-table-column prop="casecode" label="案号" align="center"></el-table-column>
          <el-table-column prop="sexname" label="性别" align="center"></el-table-column>
          <el-table-column prop="age" label="年龄" align="center"></el-table-column>
          <el-table-column prop="courtname" label="执行法院" align="center"></el-table-column>
          <el-table-column prop="publishdate" label="立案时间" align="center"></el-table-column>
          <el-table-column prop="exp_bad1_sign" label="下架标识" align="center">
            <!--              0为已下架,1/空/其他均为未下架-->
            <template slot-scope="scope">
              <div v-if="scope.row.exp_bad1_sign==='0'">
                已下架
              </div>
              <div v-else>
                未下架
              </div>
            </template>

          </el-table-column>
          <el-table-column prop="signalRating" label="信号等级" align="center">
          </el-table-column>


        </el-table>
      </div>
    </radar-block>
  </div>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
import radarBlock from "./radar-block";
import numBlock from "./num-block";
export default {
  mixins: [getReports],
  components: {
    DocumentGenerator,    radarBlock,
  },
  computed: {
    ...mapState("userReport/faYuan", ["provider", "date","courtDetailPro","executionPro","executionLimited"]),
    ...mapGetters("userReport/faYuan", [ "formData1" ,"xyp_t01ccbzzz"

    ]),
    ...mapState("userReport", ["uid", "price"]),
    shixinList(){
      if(!this.executionLimited){
        return []
      }
      try {
        return [this.executionLimited.sx1, this.executionLimited.sx2, this.executionLimited.sx3, this.executionLimited.sx4, this.executionLimited.sx5, this.executionLimited.sx6]
          .filter(item=>item!=null)
      }catch (e){
        return []
      }
    },
    xiangaoList(){
      if(!this.executionLimited){
        return []
      }
     try {
       return [this.executionLimited.xg1, this.executionLimited.xg2, this.executionLimited.xg3, this.executionLimited.xg4, this.executionLimited.xg5, this.executionLimited.xg6
         , this.executionLimited.xg7, this.executionLimited.xg8, this.executionLimited.xg9, this.executionLimited.xg10, this.executionLimited.xg11, this.executionLimited.xg12, this.executionLimited.xg13, this.execution
       ].filter(item=>item!=null)
     }catch (e){
       return []
     }
    },
    getShiXin1(){
      return [this.executionPro.execut1, this.executionPro.execut2, this.executionPro.execut3, this.executionPro.execut4, this.executionPro.execut5, this.executionPro.execut6
      , this.executionPro.execut7, this.executionPro.execut8, this.executionPro.execut9, this.executionPro.execut10, this.executionPro.execut11, this.executionPro.execut12, this.executionPro.execut13, this.executionPro.execut14].filter(item => item!=null)
    },
    getXianGao1(){
      return []
    }
  },
  methods: {
    ...mapActions("userReport/faYuan", ["getFaYuan"]),
    load(refresh = false) {
      this.getFaYuan(refresh).then(res => res && this.getReports());
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.signalRating >= 5) {
        return "c-green ft-sz-13 ft-bold";
      } else if (row.signalRating <= 2) {
        return "c-red ft-sz-13 ft-bold";
      }
      return "ft-sz-13 ft-bold";
    },
  },
  mounted() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
.el-table--enable-row-transition .el-table__body td.el-table__cell{
  background-color: #dbe6f3;
}
::v-deep .el-descriptions-item{
  background-color: aliceblue !important;
}
::v-deep .el-table__expanded-cell[class*=cell]{
  margin: 0 0 !important;
}
.memo{
  background-color: aliceblue;
  padding: 10px 10px;
  margin: 0px !important;
}
.el-table_expanded-cell[class*=cell]{
  padding: 0px !important;
}
.memo partialdiff {
  margin: 0px;
}
.tip{
  margin: 10px;
  text-align: left;
  margin-left: 30px;
  color: gray;
}
.tc {
  text-align: left;
  position: relative;
  .score {
    padding: 20px;
    &-card {
      width: 30%;
      background-color: #fff;
      box-shadow: 0px 0px 23px 0px rgba(82, 115, 227, 0.09);
      &-top {
        padding: 25px 40px;
        &-desc {
          margin-top: 17px;
          color: rgba(100, 100, 100, 1);
        }
        &-right {
          width: 68px;
          height: 68px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &-bottom {
        padding: 18px 20px;
        color: #fff;
      }
    }
    &-bottom {
      margin-top: 40px;
    }
  }
  .response {
    padding: 20px;
    &-num-block {
      margin-bottom: 10px;
    }
  }
  .no-data {
    padding: 70px;
    text-align: center;
  }
  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    .title {
      font-size: 24px;
      color: #000000;
    }
    .refreshBtn {
      background-color: #f56c6c;
    padding: 3px 5px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 14px;
    }
  }
}
@media screen and (max-width: 480px) {
  .score .fx-m-between{
    display: block;
    .score-card{
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  .response-num-block{
    display: block;
  }
}

                   .radar {
                     position: relative;

                     &-score {
                       width: 65%;
                       margin-left: 20px;

                       &-item {
                         width: 100%;
                         text-align: left;
                         margin-bottom: 20px;

                         &-name {
                           width: 75px;
                           margin-right: 20px;
                         }
                       }

                       &-tips {
                         width: 30%;
                         text-align: left;

                         h4 {
                           margin-bottom: 30px;
                         }
                       }
                     }

                     &-search {
                       padding: 0 20px;

                       .search-sum {
                         width: 30%;

                         &-top {
                           padding: 13px 20px;
                           margin-bottom: 2px;

                           .sum-num {
                             margin-left: 26px;
                             color: rgba(64, 158, 255, 1);
                           }
                         }

                         &-bottom {
                           padding: 13px 30px;

                           &-item {
                             .item-num {
                               color: rgba(64, 158, 255, 1);
                             }
                           }
                         }
                       }

                       &-num-block {
                         padding-top: 28px;
                       }
                     }

                     &-current {
                       padding-left: 20px;

                       &-bottom {
                         margin-bottom: 10px;

                         &-left {
                           width: 30%;
                           padding: 50px 20px;
                           box-sizing: border-box;
                           text-align: center;
                           margin-right: 5px;
                           vertical-align: middle;
                         }

                         .right-block {
                           width: 24%;
                           text-align: center;

                           &-top {
                             padding: 20px;
                             margin-bottom: 5px;
                           }

                           &-bottom {
                             padding: 10px;
                           }
                         }
                       }
                     }

                     &-behavior {
                       padding-left: 20px;
                     }

                     .jilu {
                       width: 100%;
                       height: 114px;
                       background: #ffffff;
                       padding: 20px 0;
                       box-sizing: border-box;
                       text-align: center;

                       .title {
                         font-size: 24px;
                         color: #000000;
                       }

                       .refreshBtn {
                         background-color: #f56c6c;
                         padding: 3px 5px;
                         color: #ffffff;
                         border: none;
                         border-radius: 3px;
                         cursor: pointer;
                         margin: 0 10px;
                         font-size: 14px;
                       }
                     }
                   }
.sect{
  text-align: left;
  margin-left: 30px;
  margin-bottom: 10px;
  font-weight: 700;
  border-left: 5px solid dodgerblue;
  padding-left: 5px;
  border-radius: 8px;
  margin-top: 20px;
}
@media screen and (max-width: 480px) {
  .radar {
    .radarBox {
      display: block;

      .radar-score {
        width: 100%;
        margin-left: 0;
      }

      .radar-score-tips {
        width: 100%;
      }
    }

    &-search {
      display: block;

      .search-sum {
        width: 100%;
      }

      .num-block {
        width: 100% !important;
        margin-bottom: 0.5rem;
      }
    }

    &-cur {
      display: block;

      .ft-medium {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

  }

  .radar-current-bottom {
    display: block;
  }
}

</style>
